import tw from 'tailwind-styled-components';

export const StyledFaqWrapper = tw.form`
  hidden md:flex mt-8 max-w-screen-sm
`;

export const StyledWrapperToolTip = tw.form`
  mb-2 mt-8 flex
`;

export const StyledWrapperLink = tw.a`
  underline text-primary text-center w-full block
`;

export const StyledSubtitle = tw.div`
  text-center mb-6 hidden md:block
`;
