import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import {
  StyledAction,
  StyledBody,
  StyledContent,
  StyledEyebrow,
  StyledFooter,
  StyledForm,
  StyledHeader,
  StyledStep,
} from './styles';

interface PrimitiveStepProps {
  children: ReactNode;
  className?: string;
}

export const Step = (props: { children: ReactNode }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
    className="flex flex-1 flex-col"
  >
    <StyledStep>{props.children}</StyledStep>
  </motion.div>
);

const Header = (props: PrimitiveStepProps) => <StyledHeader {...props} />;

const Body = (props: PrimitiveStepProps) => <StyledBody {...props} />;

const Content = (props: PrimitiveStepProps) => <StyledContent {...props} />;

const Form = (props: PrimitiveStepProps) => <StyledForm {...props} />;

const Footer = (props: PrimitiveStepProps) => <StyledFooter {...props} />;

const Eyebrow = (props: PrimitiveStepProps) => <StyledEyebrow {...props} />;

const Action = (props: PrimitiveStepProps) => <StyledAction {...props} />;

Step.Header = Header;
Step.Body = Body;
Step.Content = Content;
Step.Form = Form;
Step.Footer = Footer;
Step.Eyebrow = Eyebrow;
Step.Action = Action;
