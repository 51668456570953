import tw from 'tailwind-styled-components';

export const StyledStep = tw.section`
  flex py-4 flex-col flex-1 w-full
`;

export const StyledHeader = tw.div`
  flex-col py-2
`;

export const StyledBody = tw.div`
  flex flex-col md:flex-row flex-1 gap-x-8 mt-4 md:mt-8
`;

export const StyledContent = tw.div`
  md:w-1/2 w-full md:text-left mb-6
`;

export const StyledForm = tw.div`
  md:w-1/2 w-full flex flex-col flex-1 pb-40
`;

export const StyledFooter = tw.div`
  w-full flex flex-col items-center fixed bottom-0 left-0 p-4 bg-white z-50 shadow-top
  md:relative md:flex-initial md:shadow-none
`;

export const StyledEyebrow = tw.div`
  flex my-4
`;

export const StyledAction = tw.div`
  w-full md:max-w-80
`;
