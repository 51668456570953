import { lsI18NService } from '../../../../../service';
import { Button, Text, Icon, TextInput } from '@lawnstarter/ls-react-common/atoms';
import {
  StyledActionBar,
  StyledCardCvcElement,
  StyledCardDetail,
  StyledCardDetailsWrapper,
  StyledCardExpiryElement,
  StyledCardNumberElement,
  StyledCardWrapper,
  StyledForm,
  Styledlabel,
  elementStyles,
  eyebrowStyle,
  lockStyle,
} from './styles';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCheckoutForm } from './controller';
import { selectContact } from '../../../../../store/modules/contact/slice';
import { useSelector } from 'react-redux';
import { StyledFooter } from '../../../../../layout/Step/styles';

interface CheckoutFormProps {
  onSuccess: () => void;
}

export const CheckoutForm = ({ onSuccess }: CheckoutFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { name } = useSelector(selectContact);
  const { control, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: name ?? '',
      zip: '',
    },
  });
  const { handleSubmit, mutation } = useCheckoutForm({ ...getValues() });
  const [isCardNumberOnFocus, setIsCardNumberOnFocus] = useState(false);
  const [isCardCvcOnFocus, setIsCardCvcOnFocus] = useState(false);
  const [isCardExpiryOnFocus, setIsCardExpiryOnFocus] = useState(false);
  const [shouldCallSuccess, setShouldCallSuccess] = useState(true);
  const [shouldShowSubmit, setShouldShowSubmit] = useState(true);

  useEffect(() => {
    if (mutation.isSuccess && shouldCallSuccess) {
      onSuccess();
      setShouldCallSuccess(false);
    }
  }, [mutation.isSuccess, onSuccess, shouldCallSuccess]);

  return (
    <StyledForm onSubmit={handleSubmit} ref={formRef}>
      <TextInput
        name="name"
        control={control}
        label={`${lsI18NService.t('payment.nameInput.label')}*`}
        placeholder={lsI18NService.t('payment.nameInput.placeholder')}
        rules={{ required: true }}
      />
      <StyledCardWrapper data-testid="bar">
        <Styledlabel htmlFor="card-number">{lsI18NService.t('payment.cardNumberInput.label')}*</Styledlabel>
        <StyledCardNumberElement
          options={{ showIcon: true, style: elementStyles }}
          onFocus={() => setIsCardNumberOnFocus(true)}
          onBlur={() => setIsCardNumberOnFocus(false)}
          className={isCardNumberOnFocus ? 'shadow-input border-success' : ''}
          id="card-number"
        />
      </StyledCardWrapper>

      <StyledCardDetailsWrapper>
        <StyledCardDetail>
          <Styledlabel htmlFor="card-expiry">{lsI18NService.t('payment.cardExpiryInput.label')}*</Styledlabel>
          <StyledCardExpiryElement
            options={{ placeholder: '02/25', style: elementStyles }}
            onFocus={() => setIsCardExpiryOnFocus(true)}
            onBlur={() => setIsCardExpiryOnFocus(false)}
            className={isCardExpiryOnFocus ? 'shadow-input border-success' : ''}
            id="card-expiry"
          />
        </StyledCardDetail>

        <StyledCardDetail>
          <Styledlabel htmlFor="card-cvc">{lsI18NService.t('payment.cardCvcInput.label')}*</Styledlabel>
          <StyledCardCvcElement
            options={{ placeholder: '323', style: elementStyles }}
            onFocus={() => setIsCardCvcOnFocus(true)}
            onBlur={() => setIsCardCvcOnFocus(false)}
            className={isCardCvcOnFocus ? 'shadow-input border-success' : ''}
            id="cvc"
          />
        </StyledCardDetail>

        <StyledCardDetail>
          <TextInput
            name="zip"
            control={control}
            label={`${lsI18NService.t('payment.zipInput.label')}*`}
            placeholder={lsI18NService.t('payment.zipInput.placeholder')}
            className="mt-5"
            rules={{
              required: true,
              pattern: {
                value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                message: lsI18NService.t('payment.zipInput.invalid'),
              },
            }}
          />
        </StyledCardDetail>
      </StyledCardDetailsWrapper>

      <StyledFooter>
        <StyledActionBar>
          <Text variant="bodySmall" style={eyebrowStyle}>
            <Icon name="lock-outline" size={18} style={lockStyle} />
            {lsI18NService.t('payment.eyebrow')}
          </Text>
          <Button
            mode="contained"
            trackID={`payment-continue`}
            testID="checkout-btn"
            onPress={() => {
              setShouldShowSubmit(false);
              formRef.current?.requestSubmit();
            }}
            disabled={!formState.isValid || !shouldShowSubmit || mutation.isLoading || mutation.isSuccess}
            loading={mutation.isLoading || mutation.isSuccess}
          >
            {lsI18NService.t('continue')}
          </Button>
        </StyledActionBar>
      </StyledFooter>
    </StyledForm>
  );
};
