import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectApp, updateApp } from '../store/modules/app/slice';
import { ErrorToast } from '../components/ErrorToast';
import { lsI18NService } from '../service';
import { GraphQLHandledErrors } from '../constants/GraphQLErrors';

export function useErrorHandler() {
  const dispatch = useDispatch();

  const { error } = useSelector(selectApp);

  useEffect(() => {
    setTimeout(() => dispatch(updateApp({ error: null })), 10000);
  }, [error, dispatch]);

  const isErrorHandled = useMemo(() => (error ? GraphQLHandledErrors.includes(error) : true), [error]);

  return {
    error,
    component: () =>
      !isErrorHandled && (
        <ErrorToast
          description={lsI18NService.t('genericError')}
          msToHide={5000}
          className="fixed w-[98%] mx-[1%] bottom-4 z-50"
        />
      ),
  };
}
