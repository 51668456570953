import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { Tooltip } from '@lawnstarter/ls-react-web-common';
import dayjs from 'dayjs';

import { StepId, StepType } from '../../../enums/schema';
import { Step } from '../../../layout/Step';
import { lsI18NService } from '../../../service';
import { CartHeader } from '../../../components/CartHeader';
import { DatePicker } from '../../../components/DatePicker';
import { centsToDollar } from '../../../helpers/currency';
import { Header } from './components/Header';
import { PriceTitle } from './components/PriceTitle';
import { ErrorScreen } from '../../ErrorScreen';
import { updateApp } from '../../../store/modules/app/slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AddressButton } from '../../../components/AddressButton';
import { useScheduling } from './controller';
import { StyledSubtitle, StyledWrapperLink, StyledWrapperToolTip } from './styles';

import { useSchema } from '../../../hooks/useSchema';
import { BackButton } from '../../../components/BackButton';
import { useWwwLead } from './useWwwLead';

const stepId = StepId.Scheduling;

export const Scheduling = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { vertical } = useSchema();
  const { property, selectOptions, isLoaded, hasError, form, shouldDisplayStartDate } = useScheduling();
  const { landscapingUrl } = useWwwLead();

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: 'address' }));
    return () => {
      dispatch(updateApp({ headerRightSlot: null }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const frequency = lsI18NService.t(`cycle.${form.estimate.cycle}`);

  return !isLoaded ? (
    <></>
  ) : hasError ? (
    <ErrorScreen
      image={{ name: 'gnomeSad', isBranded: false }}
      title={lsI18NService.t('calculationScreen.error.provideQuote')}
      shouldDisplaySpinner={true}
    />
  ) : (
    <Step>
      <Step.Header>
        <CartHeader
          left={() => <BackButton className="hidden md:flex" pathname={`/${StepType.Cart}/${StepId.ContactInfo}`} />}
          right={() => <AddressButton className="md:hidden" />}
        />
      </Step.Header>

      <Step.Body>
        <Step.Content>
          <Header price={centsToDollar(form.estimate.amount)} frequency={frequency} zip={property.zip} />
        </Step.Content>

        <Step.Form>
          <PriceTitle price={centsToDollar(form.estimate.amount)} frequency={frequency} />

          <StyledSubtitle>
            <Text variant="titleMedium">{lsI18NService.t('scheduling.changeFrequency', { vertical })}</Text>
          </StyledSubtitle>

          <SelectOption
            selected={form.estimate}
            onEntitySelect={(estimate) => void form.setEstimate(estimate)}
            options={selectOptions}
          />
          {shouldDisplayStartDate && (
            <>
              <StyledWrapperToolTip>
                <Text variant="bodyMedium">{lsI18NService.t('scheduling.dateLabel')}</Text>
                <Tooltip title={lsI18NService.t('scheduling.dateTip')} enterTouchDelay={0} arrow disableFocusListener>
                  <span className="ml-1 cursor-pointer">
                    <Icon name="information-outline" color={theme.colors.primary} size={theme.spacing.s5} />
                  </span>
                </Tooltip>
              </StyledWrapperToolTip>
              <DatePicker
                format="ddd, MMM D, YYYY"
                onChange={(date: null) => {
                  form.setStartDate(date as unknown as dayjs.Dayjs);
                }}
              />
            </>
          )}

          <Step.Footer>
            <Step.Action>
              <Step.Eyebrow>
                <StyledWrapperLink href={landscapingUrl} target="_self">
                  {lsI18NService.t('scheduling.imNotLooking', { vertical })}
                </StyledWrapperLink>
              </Step.Eyebrow>
              <Button
                onPress={form.handleSubmit}
                mode="contained"
                trackID={`${stepId}-continue`}
                loading={form.mutation.isLoading || form.mutation.isSuccess}
                disabled={!form.isValid || form.mutation.isLoading || form.mutation.isSuccess}
              >
                {lsI18NService.t('scheduling.continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
