import { To, useNavigate, useParams } from 'react-router-dom';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';

import { Form } from './Form';
import { Step } from '../../../layout/Step';
import { StepTitle } from '../../../components/StepTitle';
import { lsI18NService } from '../../../service';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { StepId } from '../../../enums/schema';
import { StyledCheckboxWrapper } from './styles';
import { useCallback, useEffect } from 'react';
import { useExtraServicesController } from './controller';
import { useRefreshAvailableServicesForProspectMutation } from '../../../store/modules/prospect/api';
import { ExtraServicesStep } from '../../../types/schema';

const stepId = StepId.ExtraServices;

export const ExtraServices = () => {
  const theme = useAppTheme();
  const navigate = useNavigate();
  const { hashId: prospectId } = useParams();
  const { goToNextStep, currentStep } = useStepNavigation({ stepId });
  const [refreshAvailableServicesForProspect, { isSuccess }] = useRefreshAvailableServicesForProspectMutation();
  const { extraServices, lawnTreatment, selectedValues, onQuoteServices } = useExtraServicesController({
    currentStep: currentStep as ExtraServicesStep,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void refreshAvailableServicesForProspect({ prospectId }), []);

  const nextStep = useCallback(() => void navigate(goToNextStep as To), [navigate, goToNextStep]);

  const shouldDisplay = Boolean(lawnTreatment.data.length) && Boolean(extraServices.data.length);

  return (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle stepId={stepId} centerOnMobile={true} />
        </Step.Content>

        <Step.Form>
          {!isSuccess ? (
            <Spinner size={32} testID="extra-service-loading" />
          ) : (
            shouldDisplay && (
              <StyledCheckboxWrapper>
                <Text variant="bodyMedium">{lsI18NService.t('extraServices.lawnTreatmentTitle')}</Text>
                <Form control={lawnTreatment.control} items={lawnTreatment.data} />
                <Form control={extraServices.control} items={extraServices.data} />
              </StyledCheckboxWrapper>
            )
          )}
          <Step.Footer>
            <Step.Eyebrow>
              <Text variant="bodyMedium" style={{ color: theme.colors.outline }}>
                {lsI18NService.t('extraServices.wontBeCharged')}
              </Text>
            </Step.Eyebrow>
            <Step.Action>
              {selectedValues.length > 0 ? (
                <Button
                  onPress={() => onQuoteServices(nextStep)}
                  mode="contained"
                  trackID="quote-services-cta"
                  testID="quote-services-btn"
                >
                  {lsI18NService.t('extraServices.quoteServices')}
                </Button>
              ) : (
                <Button onPress={nextStep} mode="contained" trackID="no-thanks-cta" testID="no-thanks-btn">
                  {lsI18NService.t('extraServices.noThanks')}
                </Button>
              )}
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};
